<template>
    <div class="mt16">
        <label class="w-150 mr5">Defer Reason</label>
        <Dropdown
            v-model="deferredReasonInfo.selectedReason"
            :options="reasonOptions"
            optionLabel="text"
            placeholder="Select Deferred Reason"
            class="w-50"
            panelClass="dropdown-panel"
            @change="editDeferReasonInfo"
            :showClear="true"
        />
        <br/>
        <label class="w-150 mr5">Defer Reason Text</label>
        <InputText
            id="reason"
            type="text"
            class="p-inputtext w-50 mt-4"
            v-model="deferredReasonInfo.reasonText"
            placeholder="Type Deferred Reason"
            @change="editDeferReasonInfo"
        />
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

export default {
    name: 'DeferredReasonInfo',
    components: {
        InputText,
        Dropdown
    },
    props: {
        deferredReason: Object
    },
    mounted() {
        this.deferredReasonInfo = this.deferredReason;
    },
    data() {
        return {
            deferredReasonInfo: {
                reasonText: '',
                selectedReason: {}
            }
        };
    },
    computed: {
        reasonOptions() {
            return this.$store.getters.getAppSettings.deferredReasons.filter(x => x.isShown);
        }
    },
    methods: {
        editDeferReasonInfo() {
            this.$emit('onEdit', this.deferredReasonInfo);
        }
    }
};
</script>
<style scoped>
.pi-user-edit {
    font-size: 1.5rem !important;
}
</style>
