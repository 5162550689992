<template>
    <tr>
        <th colspan="3">
            <DeferredReasonInfo v-if="(isAssetStateDeferred || asset.state == AssetState.Deferred) && deferredReasonInfo"
                :deferredReason="deferredReasonInfo" @onEdit="(value) => { deferredReasonInfo = value }" />
        </th>
        <th colspan="5">
            <br />
            <div class="d-flex align-items-end justify-content-end flex-wrap mt16">
                <div class="d-flex">
                    <Button class="button-color-lavender-purple w-100" label="RESOLVE & NEXT"
                        @click="onSave(AssetState.ManualVerified, true)" v-if="hideButtons"
                        :disabled="isAssetStateDeferred || disableSaveAndResolveBtn" />
                </div>
                <div class="d-flex flex-column ms-3">
                    <div class="d-flex align-items-end justify-content-end flex-wrap mt-3">
                        <div class="mb-3 field-checkbox align-items-end"
                            v-if="asset.state != AssetState.Deferred && hideButtons">
                            <Checkbox class="mb-2" inputId="assetStateDeffered" v-model="isAssetStateDeferred"
                                :binary="true" @click="clearReasons" />
                            <label class="ms-2 fs-5" for="assetStateDeffered">Defer</label>
                        </div>
                    </div>
                    <Button class="button-color-lavender-purple w-100" label="SAVE" @click="onSave()"
                        v-if="hideButtons" />
                </div>
                <div class="d-flex ms-3">
                    <Button class="button-color-lavender-purple w-100" label="RESOLVE"
                        @click="onSave(AssetState.ManualVerified)" v-if="hideButtons"
                        :disabled="isAssetStateDeferred || disableSaveAndResolveBtn" />
                </div>
                <div class="ms-3">
                    <Button class="p-button-secondary" label="CANCEL"
                        @click="$router.push({ name: NAVIGATIONCONST.RESOLVECONFLICTS })" />
                </div>
            </div>
        </th>
        <ProgressSpinner v-show="loading" class="p-component-overlay position-fixed w-100 h-100" strokeWidth="4" />
    </tr>
</template>

<script>
import DeferredReasonInfo from './DeferredReasonInfo';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import {
    AssetState,
    PhysicalStatusAssets
} from '../../../../shared/enums';
import {
    msgConflictResolution,
    toasterTime,
    conflictResolutionConst,
    NAVIGATIONCONST
} from '../../../../shared/constants';
import { serverTimeStamp } from '../../../../../firebase';
import { AssetService } from '../../../../apis';
import { isNavigationAllowed } from '../../../../shared/utils';

export default {
    name: 'ConflictResolutionAction',
    components: {
        Checkbox,
        Button,
        ProgressSpinner,
        DeferredReasonInfo
    },
    props: {
        isDeferred: Boolean,
        disableSaveAndResolveBtn: Boolean,
        hideButtons: Boolean,
        assetFromOriginalIsNew: Boolean,
        currentAsset: Object,
        selectedOriginalFilter: Object,
        assetTypes: Object,
        assetVerificationData: Object
    },
    data() {
        return {
            AssetState,
            loading: false,
            loggedInUserId: this.$store.getters.getSessionUser.uid,
            isAssetStateDeferred: this.isDeferred,
            deferredReasonInfo: null,
            NAVIGATIONCONST
        };
    },
    mounted() {
        const reasonOptions = this.$store.getters.getAppSettings.deferredReasons;
        const deferredInfo = this.currentAsset?.verification?.physical.deferred;
        this.deferredReasonInfo = {
            reasonText: deferredInfo?.otherReasonText || '',
            selectedReason: reasonOptions.filter(x=>x.value == deferredInfo?.reasonValue)[0] || null
        };
    },
    computed: {
        asset() {
            /** It is need to overcome prop mutation in child component issue. */
            return JSON.parse(JSON.stringify(this.currentAsset));
        }
    },
    methods: {
        convertResolvedStringToObj() {
            const trackEditStateProperties = this.assetVerificationData.filter(x => x.data?.isTrackEditState).map(x => x.data.target.name);
            for (const key of Object.keys(this.asset.resolved)) {
                if (trackEditStateProperties.indexOf(key) != -1) {
                    this.asset.resolved[key] = {
                        isEdited: false,
                        value: this.asset.resolved[key].hasOwnProperty('value') ?
                            this.asset.resolved[key].value||'' : this.asset.resolved[key]
                    };
                }
            }
        },
        clearReasons() {
            if (!this.isAssetStateDeferred) {
                this.deferredReasonInfo.selectedReason = null;
                this.deferredReasonInfo.reasonText = '';
            }
        },
        async onSave(state, shouldShowNextAsset) {
            this.loading = true;
            const { assetId } = this.$route.params;
            const data = {};
            this.convertResolvedStringToObj();
            if (this.asset.resolved.otherManufacturer) {
                delete this.asset.resolved.otherManufacturer;
            }
            delete this.asset.resolved.Site;
            data.resolved = this.asset.resolved;
            for (const key of Object.keys(data.resolved)) {
                delete data.resolved[key]?.imageUrl;
            }

            if (this.isAssetStateDeferred ||
                (this.asset.state == AssetState.Deferred && !state)
            ) {
                state = AssetState.Deferred;
                data.state = AssetState.Deferred;
                if (
                    this.deferredReasonInfo &&
                    Object.keys(this.deferredReasonInfo).length > 0
                ) {
                    data.verification = {
                        physical: {
                            state: PhysicalStatusAssets.Deferred,
                            deferred: {
                                by: this.loggedInUserId,
                                when: serverTimeStamp(),
                                reasonValue:
                                    this.deferredReasonInfo?.selectedReason?.value || '',
                                otherReasonText: this.deferredReasonInfo?.reasonText || ''
                            }
                        }
                    };
                }
            }
            if (this.asset.resolved[conflictResolutionConst.typeId]) {
                const { SNOWType } = this.assetTypes.find(asset =>
                    asset.id == this.asset.resolved[conflictResolutionConst.typeId]
                );
                data.resolved.SNOWType = SNOWType;
            }
            if (state == AssetState.ManualVerified) {
                data.state = data.state ? data.state : state;
                data.verification = {
                    physical: {
                        state: PhysicalStatusAssets.VerifiedWithManualResolved,
                        verifiedWithResolvedConflicts: {
                            by: this.loggedInUserId,
                            when: serverTimeStamp()
                        }
                    }
                };
            }
            if (state &&
                state != AssetState.Deferred &&
                this.asset.verification?.physical?.deferred
            ) {
                data.verification.physical.deferred =
                    this.asset.verification.physical.deferred;
            }
            /* This comment is made intentionally
            Condition 1: currentAsset = New, assetFromOriginal = New => then asset from original will be discarded(state = 7).
            Condition 3: currentAsset = Old, assetFromOriginal = New => then asset from original will be discarded(state = 7).
            Condition 2: currentAsset = New, assetFromOriginal = Old => then current asset will be discarded(state = 7).
            Condition 4: currentAsset = Old, assetFromOriginal = Old => then current asset will be discarded(state = 7).
            */
            try {
                if (state) {
                    if (state == AssetState.Deferred) {
                        await AssetService.update(assetId, data);
                    } else if (this.assetFromOriginalIsNew) {
                        if (this.selectedOriginalFilter) {
                            await AssetService.update(this.selectedOriginalFilter.value, {
                                state: AssetState.ReMapped,
                                verification: {
                                    physical: {
                                        state: PhysicalStatusAssets.Discarded
                                    }
                                }
                            });
                        }
                        await AssetService.update(assetId, data);
                    } else {
                        await AssetService.update(assetId, {
                            state: AssetState.ReMapped,
                            verification: {
                                physical: {
                                    state: PhysicalStatusAssets.Discarded
                                }
                            }
                        });
                        if (this.selectedOriginalFilter) {
                            await AssetService.update(
                                this.selectedOriginalFilter.value || assetId,
                                data
                            );
                        }
                    }
                } else {
                    await AssetService.update(assetId, { resolved: data.resolved });
                }
                this.redirectNext(state, shouldShowNextAsset);
            } catch (err) {
                this.$toast.add({
                    ...msgConflictResolution.errorResolve,
                    closable: false,
                    life: toasterTime
                });
            }
        },
        async redirectNext(state, shouldShowNextAsset) {
            const conflictedAssetList = this.$store.getters.getConflictedAsests;
            const { assetId } = this.$route.params;
            let nextAssetId = '';
            const totalConflictedAssets = conflictedAssetList.length;
            if (shouldShowNextAsset && totalConflictedAssets > 0) {
                const currentAssetIndex = conflictedAssetList.findIndex(x => x.id == assetId);
                const nextAsset = currentAssetIndex == -1 ? conflictedAssetList[0] :
                    (totalConflictedAssets == currentAssetIndex + 1) ? null : conflictedAssetList[currentAssetIndex + 1];
                nextAssetId = nextAsset?.id;
            }
            if (nextAssetId) {
                this.$router.push({ name: NAVIGATIONCONST.RESOLVECONFLICT, params: { assetId: nextAssetId } });
            } else if (state != AssetState.InConflict) {
                this.$router.push({
                    name: isNavigationAllowed(NAVIGATIONCONST.OURASSETS) ?
                        NAVIGATIONCONST.OURASSETS : NAVIGATIONCONST.RESOLVECONFLICTS,
                    query: { s: state }
                });
            } else {
                this.$router.push({ name: NAVIGATIONCONST.RESOLVECONFLICTS });
            }
        }
    }
};
</script>

