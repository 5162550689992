<template>
    <div class="container container-width">
        <div class="row mx-auto" v-if="!isDataLoading">
            <template v-if="asset">
                <h3>{{assetTag}}, {{serialNumber}}</h3>
                <nav class="text-black-50" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item" v-if="districtName">{{ districtName }}</li>
                        <li class="breadcrumb-item">{{ buildingId }}</li>
                        <li class="breadcrumb-item">{{ locationId }}</li>
                    </ol>
                </nav>
            </template>
            <table class="mt-2" v-if="displayTable">
                <thead>
                    <tr class="text-center">
                        <td></td>
                        <td>Mapped With</td>
                        <td>Scanned By</td>
                        <td class="auto-resolve-col">Auto Resolved</td>
                        <td>Resolved</td>
                    </tr>
                    <tr>
                        <th class="header-col">Attributes</th>
                        <th class="school-excel-col">
                            <Dropdown
                                id="userName"
                                v-if="originalFilters.some(x => x.items.length != 0)"
                                :options="originalFilters"
                                v-model="selectedOriginalFilter"
                                placeholder="Select Original Type"
                                class="text-center original-dropdown"
                                optionLabel="label"
                                optionGroupLabel="label"
                                optionGroupChildren="items"
                                :showClear="true"
                                @change="onOriginalFiltersChange()"
                            >
                                <template #optiongroup="originalItems">
                                    <span v-if="originalItems.option.items.length>0">
                                        {{originalItems.option.label}}
                                    </span>
                                    <span v-else>
                                    </span>
                                </template>
                            </Dropdown>
                        </th>
                        <th class="text-center scanned-by-col">
                            <Dropdown
                                id="userName"
                                :options="uniqueUserName"
                                v-model="selectedUserName"
                                placeholder="Select User"
                                :class="scanArray.length > 1 ? 'user-dropdown' : 'original-dropdown'"
                                @change="onSelectedUserNameChange"
                                :disabled="checkUserNameLength"
                            />
                            <Dropdown
                                id="scan"
                                v-if="scanArray.length > 1"
                                :options="scanArray"
                                v-model="selectedScan"
                                placeholder="Select Scan"
                                class="ms-3 scan-dropdown"
                                @change="onSelectedScanChange"
                                :disabled="checkScanArrayLength"
                            />
                        </th>
                        <th colspan="2"></th>
                    </tr>
                    <tr>
                        <td><InputSwitch v-model="toggleBtnState" @change="onToggleBtnChange"/></td>
                        <td class="text-center">
                            <div v-if="mappedWithColDateLabel">
                                <span class="fw-bold" >{{ mappedWithColDateLabel }}</span>
                                {{ mappedWithColDate }}
                            </div>
                        </td>
                        <td class="text-center">
                            <span class="fw-bold">Scanned At:</span>
                            {{ Utility.formateDate(selectedScanData?.lastUpdated?.seconds, 'M/D/YY HH:mm:ss') }}
                        </td>
                        <td colspan="2"></td>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(c, index) in columns" :key="index">
                        <template v-if="c.fields.length">
                            <tr class="text-primary fw-bold" v-if="c.name != conflictResolutionConst.Identification">
                                <td>
                                    <span v-if="toggleBtnState || headerForDisplayedFields.includes(c.name)">
                                        {{ c.name }}
                                    </span>
                                </td>
                            </tr>
                            <tr class="fw-bold" v-else>
                                <td class="text-primary">
                                    <span v-if="toggleBtnState || headerForDisplayedFields.includes(c.name)">
                                        {{ c.name }}
                                    </span>
                                </td>
                                <td>
                                    <template v-if="(asset.mappedWith.serialNumber || asset.mappedWith.assetTag) && hideButtons">
                                        <RadioButton
                                        class="align-middle me-1"
                                        name='all'
                                        :value="'mappedWith'"
                                        v-model="selectAllType"
                                        @change="onSelectAll"
                                    />
                                    Select All
                                    </template>
                                </td>
                                <td>
                                    <template v-if="hideButtons">
                                        <RadioButton
                                            class="align-middle me-1"
                                            name='all'
                                            :value="'selectedScan'"
                                            v-model="selectAllType"
                                            @change="onSelectAll"
                                        />
                                        Select All
                                    </template>
                                </td>
                                <td class="ps-4">
                                    <template v-if="Object.keys(asset.autoResolved).length && hideButtons">
                                        <RadioButton
                                        class="align-middle me-1"
                                        name='all'
                                        :value="'autoResolved'"
                                        v-model="selectAllType"
                                        @change="onSelectAll"
                                    />
                                    Select All
                                    </template>
                                </td>
                            </tr>
                            <template v-for="{ field, header, hasConflict, isHidden } in c.fields" :key="field">
                                <tr class="color-row" v-if="!isHidden &&
                                (toggleBtnState || fieldsToDisplayWhenToggleBtnOff.includes(field))">
                                    <td class="header">
                                        <span :class="inConflictkeys.includes(field) ? 'dark-pink-text' : ''">
                                            {{ header }}
                                        </span>
                                    </td>
                                    <td>
                                        <template v-if="asset.mappedWith[field] != UNDEFINED && asset.mappedWith[field]">
                                            <RadioButton
                                                v-if="hideButtons"
                                                class="align-middle me-1"
                                                name="field"
                                                :value="getRadioValue(conflictResolutionConst.mappedWith, field)"
                                                v-model="radioSelection[field]"
                                                @change="onRadioButtonSelection(field, false, conflictResolutionConst.mappedWith)"
                                            />
                                        <span :class="inConflictkeys.includes(field) ? 'dark-pink-text' : ''" >{{ getTransformedValue(asset.mappedWith[field], field, conflictResolutionConst.mappedWith) }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="selectedScan && selectedScanData.data[field] &&
                                        (selectedScanData.data[field].value != UNDEFINED ||
                                        selectedScanData.data[field] != UNDEFINED )">
                                            <RadioButton
                                                v-if="(selectedScanData.data[field].value || selectedScanData.data[field]) && hideButtons"
                                                id="binary"
                                                class="align-middle me-1"
                                                name="field"
                                                :value="getRadioValue(conflictResolutionConst.selectedScan, field)"
                                                v-model="radioSelection[field]"
                                                @change="onRadioButtonSelection(field, true, conflictResolutionConst.selectedScan)"
                                            />
                                        <span :class="inConflictkeys.includes(field) ? 'dark-pink-text' : ''">{{ getTransformedValue(selectedScanData.data[field], field, conflictResolutionConst.selectedScan) }}</span>
                                            <span
                                                v-if="selectedScanData.data[field].isEdited"
                                                class="edited size12 position-relative"
                                                ><i class="pi pi-user-edit p-1"></i></span
                                            >
                                            <span
                                                v-if="(selectedScanData.data[field].value ||
                                                selectedScanData.data[field]) && hasConflict"
                                                class="legend-key m-0 p-1"
                                            />
                                            <span
                                                v-if="header == 'Serial #' && selectedScanData.data.serialNumber?.imageUrl"
                                                class="edited size12 position-relative p-1"
                                                ><i class="pi pi-image"
                                                    @click="showImageDialog(
                                                    selectedScanData.data.serialNumber?.imageUrl,
                                                    selectedScanData.data.serialNumber?.value || selectedScanData.data.serialNumber)"></i>
                                            </span>
                                            <span
                                                v-if="header == 'Manufacturer' && selectedScanData.data.otherManufacturer?.imageUrl"
                                                class="edited size12 position-relative p-1"
                                                ><i class="pi pi-image"
                                                    @click="showImageDialog(
                                                    selectedScanData.data.otherManufacturer?.imageUrl,
                                                    selectedScanData.data.otherManufacturer?.value || selectedScanData.data.otherManufacturer)"></i>
                                            </span>
                                        </template>
                                    </td>
                                    <td class="ps-4">
                                        <template v-if="asset.autoResolved[field]?.executed?.value != UNDEFINED
                                        && asset.autoResolved[field]?.executed?.value">
                                            <RadioButton
                                                v-if="hideButtons"
                                                class="align-middle me-1"
                                                name="field"
                                                :value="getRadioValue(conflictResolutionConst.autoResolved, field)"
                                                v-model="radioSelection[field]"
                                                @change="onRadioButtonSelection(field, false, conflictResolutionConst.autoResolved)"
                                            />
                                            <span>{{ getTransformedValue(asset.autoResolved[field]?.executed, field,
                                            conflictResolutionConst.autoResolved) }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="selectedScan">
                                            <div class="my-1">
                                                <Dropdown
                                                    id="resolvedDropDown"
                                                    v-if="dropDownForFields.includes(field)"
                                                    :options="dropDownData[field]"
                                                    v-model="selectedDropDownValues[field]"
                                                    optionLabel="label"
                                                    placeholder="Select option"
                                                    class="p-inputtext-sm w-100"
                                                    @change="onResolvedDropDownChange(field)"
                                                    :class="{'': ((selectedScanData?.data[field]?.value ||
                                                    selectedScanData?.data[field]) && hasConflict)}"
                                                    :disabled="disableInputFields"
                                                    :filter="field == conflictResolutionConst.Site"
                                                    filterPlaceholder="Search"
                                                    panelClass="dropdown-panel"

                                                />
                                                <InputText
                                                    v-else-if="(asset.resolved[field]?.value || asset.resolved[field]?.value == '')
                                                        && !dropDownForFields.includes(field)"
                                                    v-model="asset.resolved[field].value"
                                                    :placeholder="header"
                                                    type="text"
                                                    class="p-inputtext-sm w-100"
                                                    @input="updateRadioSelection(field, $event)"
                                                    :class="{'': ((selectedScanData?.data[field]?.value ||
                                                    selectedScanData?.data[field]) && hasConflict)}"
                                                    :disabled="disableInputFields"
                                                />
                                                <InputText
                                                    v-else
                                                    v-model="asset.resolved[field]"
                                                    :placeholder="header"
                                                    type="text"
                                                    class="p-inputtext-sm w-100"
                                                    @input="updateRadioSelection(field, $event)"
                                                    :class="{'': ((selectedScanData?.data[field]?.value ||
                                                    selectedScanData?.data[field]) && hasConflict)}"
                                                    :disabled="disableInputFields"
                                                />
                                            </div>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                    <ConflictResolutionAction
                        :currentAsset="asset"
                        :isDeferred="isAssetStateDeferred"
                        :disableSaveAndResolveBtn="disableSaveAndResolveBtn"
                        :hideButtons="hideButtons"
                        :assetFromOriginalIsNew="assetFromOriginalIsNew"
                        :selectedOriginalFilter="selectedOriginalFilter"
                        :assetTypes="assetTypes"
                        :assetVerificationData="assetVerificationData"
                    />
                </tbody>
            </table>
            <div v-else-if="asset" class="text-center text-danger mt-5">
                There is nothing to resolve for this asset.
            </div>
            <div v-else class="text-center text-danger mt-5">
                There is no Asset record from given ID
            </div>
            <div class="row my-5 pe-5">
                <Dialog
                    v-if="popup.imageUrl"
                    :header="`(${popup.header})`"
                    v-model:visible="popup.visible"
                    :maximizable="true"
                    :breakpoints="{ '960px': '75vw' }"
                    :style="{ width: '50vw' }"
                >
                    <img :src="popup.imageUrl" alt="Asset QR Scan Image" style="pointer-events: none" />
                </Dialog>
            </div>
        </div>
        <ProgressSpinner v-show="loading" class="p-component-overlay position-fixed w-100 h-100" strokeWidth="4" />
    </div>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';
import RadioButton from 'primevue/radiobutton';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import uniq from 'uniq';
import {
    AssetService,
    FileStorageService,
    UserService,
    AssetMapService,
    AssetVerificationsService,
    BuildingService,
    LocationService,
    AssetTypeService,
    VisitService
} from '../../../../apis';
import { FieldGroup } from './ConflictStructure';
import DeferredReasonInfo from './DeferredReasonInfo.vue';
import { AssetState, PhysicalStatusAssets, LastUpdatedSource, VisitStatus } from '../../../../shared/enums';
import ConflictResolutionAction from './ConflictResolutionAction';
import {
    NAVIGATIONCONST,
    assetVerificationKey,
    imageDimensions,
    UNDEFINED,
    dropDownForFields,
    otherManufacturer,
    getDropdownItems,
    conflictResolutionConst,
    unknown,
    fieldDataUnknown,
    dataType,
    unassignedDistrict,
    errorTag,
    UNK,
    conflictKeysToIncludeForTextHighlight,
    fieldsToDisplayWhenToggleBtnOff,
    automaticResolvedFields
} from '../../../../shared/constants';
import { Utility, getSplitedText, sortObjectArray } from '../../../../shared/utils';

export default {
    name: 'ConflictResolution',
    components: {
        Dialog,
        InputText,
        ProgressSpinner,
        Dropdown,
        RadioButton,
        InputSwitch,
        ConflictResolutionAction
    },
    data() {
        return {
            hideButtons: true,
            isAssetStateDeferred: false,
            AssetState,
            Utility,
            UNDEFINED,
            dropDownForFields,
            otherManufacturer,
            getDropdownItems,
            conflictResolutionConst,
            unassignedDistrict,
            NAVIGATIONCONST,
            LastUpdatedSource,
            asset: {
                scanned: [],
                scannedBy: {},
                resolved: {},
                original: {},
                mappedWith: {},
                autoResolved: []
            },
            radioSelection: {},
            loading: false,
            columns: [],
            popup: {
                header: '',
                visible: true,
                imageUrl: ''
            },
            uniqueUserName: [],
            originalFilters: [
                {
                    label: 'Matched Asset Tag',
                    items: []
                },
                {
                    label: 'Matched Serial Number',
                    items: []
                }
            ],
            selectedUserName: null,
            selectedOriginalFilter: null,
            scanArray: [],
            selectedScan: null,
            selectedScanData: null,
            checkUserNameLength: false,
            checkScanArrayLength: false,
            assetVerificationData: [],
            currentScanResolvedKeys: [],
            dropDownData: {},
            selectedDropDownValues: {},
            selectAllType: null,
            hideInputButtonForStates: [AssetState.ManualVerified],
            assetFromOriginalIsNew: false,
            disableSaveAndResolveBtn: null,
            displayTable: true,
            assetTypes: [],
            resolvedKeys: [],
            mappedWithColDateLabel: null,
            inConflictkeys: [],
            loggedInUserId: this.$store.getters.getSessionUser.uid,
            toggleBtnState: false,
            headerForDisplayedFields: [],
            fieldsToDisplayWhenToggleBtnOff,
            isDataLoading: false,
            visitStateMap: {}
        };
    },
    async mounted() {
        this.loading = this.isDataLoading = true;
        await this.showdata();
        if (!this.displayTable) {
            return;
        }
        this.getFieldOptions();
        this.hideButtons = this.hideInputButtonForStates.every(x => x != this.asset.state);
        this.disableInputFields = this.hideInputButtonForStates.some(x => x == this.asset.state);
        this.checkSaveDisable();
        this.loading = this.isDataLoading = false;
    },
    watch: {
        'asset.resolved': {
            handler: function() {
                this.checkSaveDisable();
            },
            deep: true
        }
    },
    methods: {
        checkSaveDisable() {
            if (this.asset) {
                this.disableSaveAndResolveBtn = this.resolvedKeys.some(x => {
                    const typeOfField = typeof this.asset.resolved[x];
                    const objectVerification = typeOfField == dataType.Object && this.asset.resolved[x].value.trim().length;
                    const stringVerification = typeOfField == dataType.String && this.asset.resolved[x].trim().length;
                    return !(objectVerification || stringVerification);
                });
            }
        },
        onToggleBtnChange() {
            if (!this.toggleBtnState && !this.headerForDisplayedFields.length) {
                const fieldHeader = new Set();
                Object.entries(FieldGroup).forEach(field => {
                    field[1].forEach(x => {
                        if (fieldsToDisplayWhenToggleBtnOff.includes(x)) {
                            fieldHeader.add(field[0]);
                        }
                    });
                });
                this.headerForDisplayedFields = Array.from(fieldHeader);
            }
            if (this.asset.state != AssetState.ManualVerified) {
                const scanData = JSON.parse(JSON.stringify(this.selectedScanData.data));
                automaticResolvedFields.forEach(field => {
                    if (this.selectedScanData.data[field] && !this.tempResolveObj[field]) {
                        this.asset.resolved[field] = (field in this.tempResolveObj) ? this.tempResolveObj[field] : scanData[field];
                    }
                    if (dropDownForFields.includes(field)) {
                        this.changeDropDownOnResolveChange(field);
                    }
                });
            }
        },
        getOptionNameFromValue(field, value) {
            let dropDownValueName = '';
            if (field == conflictResolutionConst.Site) {
                return value;
            } else if (
                (field != conflictResolutionConst.typeId && field != conflictResolutionConst.snowType && value )&&
                ((value.value && value.value.toUpperCase()) || value.toUpperCase()) == unknown.toUpperCase()
            ) {
                return unknown;
            }
            if (field == conflictResolutionConst.typeId) {
                this.assetTypes.forEach(assetType => {
                    if (assetType.id == value) {
                        dropDownValueName = assetType.name;
                        return;
                    }
                });
            } else {
                this.assetVerificationData.forEach(verificationItem => {
                    if (verificationItem.data?.target?.name == field) {
                        verificationItem.items?.fixed?.forEach(element => {
                            if (element.value == value) {
                                dropDownValueName = element.text;
                                return;
                            }
                        });
                    }
                });
            }
            return dropDownValueName;
        },
        onSelectAll() {
            for (const column of this.columns) {
                for (const i of column.fields) {
                    switch (this.selectAllType) {
                    case conflictResolutionConst.mappedWith:
                        this.radioSelection[i.field] = Object.assign({}, { from: conflictResolutionConst.mappedWith,
                            value: this.asset.mappedWith[i.field] });
                        // In some asset original object has undefined as string. DO NOT COPY THIS CONDITION
                        if (this.asset.mappedWith[i.field] != UNDEFINED && this.asset.mappedWith[i.field] != null) {
                            this.onRadioButtonSelection(i.field, false, conflictResolutionConst.mappedWith);
                        }
                        break;
                    case conflictResolutionConst.selectedScan:
                        this.radioSelection[i.field] =
                            Object.assign({}, { from: conflictResolutionConst.selectedScan, value: this.selectedScanData.data[i.field] });
                        if (this.selectedScanData.data[i.field]) {
                            this.onRadioButtonSelection(i.field, true, conflictResolutionConst.selectedScan);
                        }
                        break;
                    case conflictResolutionConst.autoResolved:
                        this.radioSelection[i.field] = Object.assign({}, { from: conflictResolutionConst.autoResolved,
                            value: this.asset.autoResolved[i.field]?.executed?.value });
                        if (this.asset.autoResolved[i.field]) {
                            this.onRadioButtonSelection(i.field, false, conflictResolutionConst.autoResolved);
                        }
                    }
                }
            }
            this.changeDropDownOnResolveChange();
        },
        onResolvedDropDownChange(field) {
            if (field == conflictResolutionConst.Site) {
                [this.asset.resolved.locationId, this.asset.resolved.buildingId] = (this.selectedDropDownValues[field].value).split('@');
            } else {
                this.asset.resolved[field] = this.selectedDropDownValues[field].value;
            }
            this.radioSelection[field] = this.selectedDropDownValues[field].value;
            this.selectAllType = null;
        },
        // Get text from value for a field
        getTransformedValue(fieldData, field, source) {
            fieldData = fieldData?.value || fieldData;
            if (source != conflictResolutionConst.mappedWith && field == conflictResolutionConst.manufacturer &&
                fieldData == conflictResolutionConst.Other) {
                let otherManufacturer;
                switch (source) {
                case conflictResolutionConst.selectedScan:
                    otherManufacturer = this.selectedScanData?.data?.otherManufacturer?.value;
                    break;
                case conflictResolutionConst.autoResolved:
                    otherManufacturer = this.asset?.autoResolved?.otherManufacturer?.executed?.value;
                }
                if (otherManufacturer) {
                    return `Other-${ otherManufacturer == errorTag ? UNK : otherManufacturer}`;
                }
            }
            if (field == conflictResolutionConst.typeId ) {
                const typeId = this.setTypeIdValue(source);
                return typeId && typeId.length > 0 && this.assetTypes.find(x => x.id == typeId).name ? this.assetTypes.find(x => x.id == typeId).name : this.assetTypes.find(x => x.id == fieldDataUnknown).name;
            }
            if (fieldData && (getDropdownItems.includes(field))) {
                return this.getOptionNameFromValue(field, fieldData);
            }
            return fieldData == errorTag ? UNK : fieldData;
        },
        setTypeIdValue(source) {
            let typeId;
            switch (source) {
            case conflictResolutionConst.selectedScan:
                typeId = this.selectedScanData?.data?.typeId;
                break;
            case conflictResolutionConst.autoResolved:
                typeId = this.asset?.autoResolved?.typeId?.executed?.value;
                break;
            case conflictResolutionConst.mappedWith:
                typeId = this.asset?.mappedWith?.typeId;
                break;
            }
            return typeId;
        },
        getRadioValue(source, field) {
            let value;
            switch (source) {
            case conflictResolutionConst.mappedWith:
                value = this.asset.mappedWith[field];
                break;
            case conflictResolutionConst.selectedScan:
                if (field == conflictResolutionConst.manufacturer && this.selectedScanData?.data?.otherManufacturer?.value) {
                    value = this.selectedScanData?.data?.otherManufacturer?.value;
                } else {
                    value = this.selectedScanData.data[field];
                }
                break;
            case conflictResolutionConst.autoResolved:
                if (field == conflictResolutionConst.manufacturer && this.autoResolved?.otherManufacturer?.executed.value) {
                    value = this.autoResolved?.otherManufacturer?.executed.value;
                } else {
                    value = this.asset.autoResolved[field].executed.value;
                }
                break;
            }
            return { from: source, value: value };
        },
        getFieldOptions() {
            // Pre-processing to get dropDown options
            this.assetVerificationData.forEach(verificationItem => {
                let options = [];
                const targetName = verificationItem.data?.target?.name;
                if (targetName == conflictResolutionConst.typeId) {
                    this.assetTypes.forEach(assetType => {
                        assetType['text'] = assetType.name;
                        assetType['value'] = assetType.id;
                        options.push(...this.pushDropdownOptions(assetType, targetName));
                    });
                } else {
                    if (verificationItem.items?.dynamic) {
                        Object.keys(verificationItem.items.dynamic).forEach(key => {
                            verificationItem.items.dynamic[key].forEach(element => {
                                options.push(...this.pushDropdownOptions(element, targetName));
                            });
                        });
                    }
                    if (verificationItem.items?.fixed) {
                        verificationItem.items?.fixed?.forEach(element => {
                            options.push(...this.pushDropdownOptions(element, targetName));
                        });
                    }
                }
                options = options.filter((a, i) => options.findIndex(s => a.label === s.label) === i);
                options = sortObjectArray(options, 'label');
                this.dropDownData[targetName] = options;
            });
        },
        pushDropdownOptions(element, targetName) {
            const options = [];
            options.push({ label: `${ element.text }`, value: `${ element.value }` });
            if (dropDownForFields.includes(targetName) && element.value == this.asset.resolved[targetName]) {
                this.selectedDropDownValues[targetName] = { label: `${ element.text }`, value: `${ element.value }` };
            }
            return options;
        },
        getBuildingIds() {
            let buildingIds = [];
            if (this.asset.mappedWith?.buildingId) {
                buildingIds.push(this.asset.mappedWith.buildingId);
            }
            this.asset.scanned.forEach(x=>{
                buildingIds.push(x.data.buildingId);
            });
            buildingIds = buildingIds.filter(x=> x != '');
            return buildingIds;
        },
        async getDropdownItemsForSite() {
            this.loading = true;
            const resolved = this.asset.resolved;
            const buildingsInfo = await BuildingService.getBuildingsById(this.getBuildingIds());
            const filterCondition = { isInScope: { matchMode: '==', value: 1 } };
            const locations = await LocationService.getAll(filterCondition);
            this.dropDownData.Site = [];
            buildingsInfo.forEach(building => {
                building.location.forEach(location => {
                    if (locations.find(x=>x.id == location)) {
                        this.dropDownData.Site.push(this.getSiteFromLocationAndBuilding(location, building.id));
                    }
                });
            });
            if (resolved.locationId && resolved.buildingId) {
                this.selectedDropDownValues.Site = this.getSiteFromLocationAndBuilding(resolved.locationId, resolved.buildingId);
            }
            this.loading = false;
        },
        getSiteFromLocationAndBuilding(location, buildingId) {
            const site = `${ location }@${ buildingId }`;
            return { label: site, value: site };
        },
        onRadioButtonSelection(field, isScanBy, source) {
            if (source != this.selectAllType) {
                this.selectAllType = null;
            }
            if (field == conflictResolutionConst.manufacturer && this.radioSelection[field].value == conflictResolutionConst.Other) {
                switch (source) {
                case conflictResolutionConst.selectedScan:
                    this.radioSelection[field].value = this.selectedScanData.data.otherManufacturer.value;
                    break;
                case conflictResolutionConst.autoResolved:
                    this.radioSelection[field].value = this.asset.autoResolved.otherManufacturer.executed.value;
                }
            }
            if (field == conflictResolutionConst.typeId ) {
                this.radioSelection[field].value = this.setTypeIdValue(source);
            }
            if (field == conflictResolutionConst.Site) {
                [this.asset.resolved.locationId, this.asset.resolved.buildingId] = (this.radioSelection[field].value).split('@');
            }
            let radioValue = JSON.parse(JSON.stringify(this.radioSelection[field].value));
            radioValue = radioValue == errorTag ? UNK : radioValue;
            if(radioValue.value)
                radioValue.value = radioValue.value == errorTag ? UNK : radioValue.value;
            this.asset.resolved[field] = radioValue;
            if (this.currentScanResolvedKeys.indexOf(field) == -1 && isScanBy) {
                if (field == conflictResolutionConst.Site) {
                    this.currentScanResolvedKeys.push(conflictResolutionConst.locationId, conflictResolutionConst.buildingId);
                } else {
                    this.currentScanResolvedKeys.push(field);
                }
            } else if (this.currentScanResolvedKeys.indexOf(field) != -1 && !isScanBy) {
                this.currentScanResolvedKeys.splice(this.currentScanResolvedKeys.indexOf(field), 1);
            }
            this.changeDropDownOnResolveChange(field);
        },
        changeDropDownOnResolveChange(field) {
            if (field) {
                this.selectedDropDownValues[field] = { label: `${ this.getOptionNameFromValue(field, this.asset.resolved[field]) }`,
                    value: `${ this.asset.resolved[field] }` };
            } else {
                Object.keys(this.asset.resolved).forEach(field => {
                    this.selectedDropDownValues[field] = { label: `${ this.getOptionNameFromValue(field, this.asset.resolved[field]) }`,
                        value: `${ this.asset.resolved[field] }` };
                });
            }
        },
        async showdata() {
            this.loading = true;
            const { assetId } = this.$route.params;
            let value = null;
            if (assetId) {
                value = await AssetService.getById(assetId);
            }
            const filteredScanArray = [];
            const visits = await VisitService.getByIds(value.scanned.map(x => x.visitId));
            for(let i of value.scanned) {
                if (visits.find(x => x.id == i.visitId)?.state != VisitStatus.Cancelled) {
                    filteredScanArray.push(i);
                }
            }
            value.scanned = filteredScanArray;
            
            if (!value || !value?.scanned?.length || filteredScanArray.length < 1) {
                this.asset = false;
                this.displayTable = false;
                this.loading = this.isDataLoading = false;
                return;
            }
            this.asset = { ...this.asset, ...value };
            this.asset.state = this.asset.state || AssetState.Unverified;
            if (this.asset.original.assetTag && this.asset.original.serialNumber) {
                this.assetTag = this.asset.original.assetTag == errorTag ? UNK : this.asset.original.assetTag;
                this.serialNumber = this.asset.original.serialNumber == errorTag ? UNK : this.asset.original.serialNumber;
            } else if (this.asset.scanned[0].data) {
                const scannedData = this.asset.scanned[0].data;
                this.assetTag = scannedData.assetTag.value || scannedData.assetTag;
                this.serialNumber = scannedData.serialNumber.value || scannedData.serialNumber;
                this.buildingId = scannedData.buildingId;
                this.locationId = scannedData.locationId;
            }
            this.assetTag = this.assetTag == errorTag ? UNK : this.assetTag;
            this.serialNumber = this.serialNumber == errorTag ? UNK : this.serialNumber;
            if (this.asset.original.buildingId && this.asset.original.locationId) {
                this.buildingId = this.asset.original.buildingId;
                this.locationId = this.asset.original.locationId;
            }
            let buildingId = this.asset.original.buildingId;
            if (!buildingId) {
                buildingId = this.asset.scanned[0].data.buildingId;
            }
            const buildingInfo = await BuildingService.getById(buildingId);
            this.districtName = buildingInfo && buildingInfo.districtId != unassignedDistrict ?
                `District ${ buildingInfo.districtId }` : '';
            if ([AssetState.Unverified, AssetState.ApprovedForServiceNowUpdate,
                AssetState.SentToServiceNow, AssetState.Scanned].indexOf(this.asset.state) !== -1) {
                this.displayTable = false;
                this.loading = this.isDataLoading = false;
                return;
            }
            // this.isAssetStateDeferred = this.asset.state == AssetState.Deferred;
            this.isCurrentAssetNew = (this.asset.original.assetTag && this.asset.original.serialNumber) ? false : true;
            await this.prepareOriginalDropDownData();
            const asset = this.asset;
            const columnData = asset.scanned.map(s => s.data);
            const column = new Set(columnData.map(Object.keys).flat());
            for (const [sectionName, sectionKeys] of Object.entries(FieldGroup)) {
                const columnContent = sectionKeys.filter(column.delete.bind(column)).map(key => ({
                    field: key,
                    fieldChecked: new Array(asset.scanned.length).fill(1).map(() => ({ [key]: false })),
                    header: getSplitedText(key.charAt(0).toUpperCase() + key.slice(1)).replace('Number', '#'),
                    hasConflict: uniq(columnData.map(a => a[key]?.value || a[key])).length > 1,
                    isHidden: key == conflictResolutionConst.locationId || key == conflictResolutionConst.buildingId
                }));
                this.columns.push({
                    name: sectionName,
                    fields: columnContent
                });
            }
            this.columns[1].fields.unshift({
                field: conflictResolutionConst.Site,
                fieldChecked: [{ Site: false }],
                header: conflictResolutionConst.Site,
                hasConflict: uniq(columnData.map(a => a[conflictResolutionConst.locationId]?.value ||
                a[conflictResolutionConst.locationId])).length > 1 ||
                uniq(columnData.map(a => a[conflictResolutionConst.buildingId]?.value || a[conflictResolutionConst.buildingId])).length > 1,
                isHidden: false
            });
            this.fillResolvedKeys();
            const scanned = asset.scanned.filter(scannedRecord => asset.deduped?.indexOf(scannedRecord.scannedId) != -1);
            const scanMap = new Map();
            const promises = [];
            this.assetTypes = await AssetTypeService.getAll();
            this.assetVerificationData = await AssetVerificationsService.getAll();
            const imagePropertyNames = this.assetVerificationData.filter(data => data.captureImage);
            scanned.forEach((s, i) => {
                s.userId && scanMap.set(s.userId, [...(scanMap.get(s.userId) || []), i]);
                let endS = { seconds: 0 };
                for (const [, { end }] of Object.entries(s.duration.verifications)) {
                    endS = end?.seconds > endS.seconds ? end : endS;
                }
                s.lastUpdated = endS;
                for (const imagePropertyName of imagePropertyNames) {
                    const oldImageFileName = s.data[imagePropertyName.data.target.name]?.image;
                    if (oldImageFileName) {
                        promises.push(FileStorageService.getDownloadURL(oldImageFileName.replace('.jpg', imageDimensions.dimensions)).catch(console.warn));
                        promises.push(FileStorageService.getDownloadURL(oldImageFileName).catch(console.warn));
                    }
                }
            });
            promises.push(UserService.getByIds(Array.from(scanMap.keys())));
            const data = await Promise.all(promises);
            const users = data.pop();
            for (const [userId, si] of scanMap) {
                const user = users.find(u => u.id == userId);
                si.forEach(i => (scanned[i].userName = user.name));
            }
            this.uniqueUserName = Array.from(new Set(scanned.map(x => x.userName)));
            this.checkUserNameLength = this.uniqueUserName.length === 1;
            this.selectedUserName = this.uniqueUserName[0];
            this.tempResolveObj = JSON.parse(JSON.stringify(this.asset.resolved));
            await this.onOriginalFiltersChange();
            await this.onSelectedUserNameChange();
            await this.getDropdownItemsForSite();
            this.loading = true;
            scanned.forEach((s, i) => {
                for (const imagePropertyName of imagePropertyNames) {
                    const imagePropertyTargetName = imagePropertyName.data.target.name;
                    if (s.data[imagePropertyTargetName]) {
                        s.data[imagePropertyTargetName].imageUrl = s.data[imagePropertyTargetName].image ?
                        data.find(x => decodeURIComponent(x).includes(s.data[imagePropertyTargetName].image?.replace('.jpg', imageDimensions.dimensions))) ? 
                        data.find(x => decodeURIComponent(x).includes(s.data[imagePropertyTargetName].image?.replace('.jpg', imageDimensions.dimensions))) : data.find(x => decodeURIComponent(x).includes(s.data[imagePropertyTargetName].image)) : '';
                    }
                }
            });
            if (this.asset.autoResolved?.locationId && this.asset.autoResolved?.buildingId) {
                this.asset.autoResolved.Site = { executed: { value:
                    `${ this.asset.autoResolved.locationId.executed.value }@${ this.asset.autoResolved.buildingId.executed.value }`
                }
                };
            }
        },
        fillResolvedKeys() {
            const ignoredResolvedKeys = [
                conflictResolutionConst.Site,
                conflictResolutionConst.floorNumber,
                conflictResolutionConst.roomNumber
            ];
            this.columns.forEach(x => {
                x.fields.forEach(y => {
                    !ignoredResolvedKeys.includes(y.field) && this.resolvedKeys.push(y.field);
                });
            });
        },
        showImageDialog(imageUrl, header) {
            this.popup = {
                imageUrl,
                header,
                visible: true
            };
        },
        async onSelectedUserNameChange() {
            const scans = this.asset.scanned.filter(x => x.userName === this.selectedUserName);
            this.scanArray = scans.map((x, i) => 'Scan ' + ++i);
            this.checkScanArrayLength = this.scanArray.length === 1;
            this.selectedScan = this.scanArray[0];
            await this.onSelectedScanChange();
        },
        async onSelectedScanChange() {
            this.selectedScanData = this.asset.scanned.filter(x => x.userName === this.selectedUserName)[
                this.scanArray.indexOf(this.selectedScan)
            ];
            const value = this.assetVerificationData.find(x => x.id === assetVerificationKey.operational ||
            x.id === assetVerificationKey.physicalCondition || x.id === assetVerificationKey.poweredOn);
            value?.items?.fixed?.forEach(x => {
                switch (x.value) {
                case this.selectedScanData.data.operational:
                    this.selectedScanData.data.operational = x.text;
                    break;
                case this.selectedScanData.data.physicalCondition:
                    this.selectedScanData.data.physicalCondition = x.text;
                    break;
                case this.selectedScanData.data.powersOn:
                    this.selectedScanData.data.powersOn = x.text;
                    break;
                }
            });
            if (this.selectedScanData.data.locationId && this.selectedScanData.data.buildingId) {
                this.selectedScanData.data.Site = `${ this.selectedScanData.data.locationId }@${ this.selectedScanData.data.buildingId }`;
            }
            this.getConflictedKeys();
            this.onToggleBtnChange();
        },

        async prepareOriginalDropDownData() {
            const assetTag = this.asset.scanned[0].data.assetTag.value;
            const serialNumber = this.asset.scanned[0].data.serialNumber.value;
            const [serialNumberMapping, assetTagMapping] = await Promise.all([
                AssetMapService.getSerialNumberMapping(serialNumber),
                AssetMapService.getAssetTagMapping(assetTag)
            ]);
            let combinedArray = serialNumberMapping.concat(assetTagMapping);
            combinedArray = combinedArray.filter(x => x.serialNumber != UNK);
            const assetIds = [];
            combinedArray.forEach(x => {
                assetIds.push(x.assetId);
            });
            const result = await AssetService.getByIds(assetIds);
            const assetStateMap = Object.fromEntries(result.map(({ id, state }) => [id, state || AssetState.Unverified]));
            const availableAssetState = [AssetState.Unverified, AssetState.ManualVerified, AssetState.AutoVerified];
            assetTagMapping.forEach(element => {
                if (element.serialNumber != serialNumber && (availableAssetState.indexOf(assetStateMap[element.assetId]) != -1) &&
                (element.assetTag != errorTag && element.serialNumber != errorTag)) {
                    this.originalFilters[0].items.push({ label: `${ assetTag }, ${ element.serialNumber }`, value: element.assetId });
                }
            });
            serialNumberMapping.forEach(element => {
                if (element.assetTag != assetTag && (availableAssetState.indexOf(assetStateMap[element.assetId]) != -1) &&
                (element.serialNumber != errorTag && element.assetTag != errorTag)) {
                    this.originalFilters[1].items.push({ label: `${ element.assetTag }, ${ serialNumber }`, value: element.assetId });
                }
            });
        },
        async onOriginalFiltersChange() {
            this.loading = true;
            let assetData = this.asset;
            this.mappedWithColDate = null;
            this.assetFromOriginalIsNew = (assetData.original.assetTag && assetData.original.serialNumber && this.selectedOriginalFilter) ?
                false : true;
            const lastUpdatedDate = assetData.original.lastUpdated.datetime;
            if (this.selectedOriginalFilter) {
                assetData = await AssetService.getById(this.selectedOriginalFilter.value);
                switch (assetData.state) {
                case AssetState.Unverified:
                    this.asset.mappedWith = assetData.original;
                    this.mappedWithColDateLabel = conflictResolutionConst.inSnowAt;
                    this.mappedWithColDate = Utility.formateDate(lastUpdatedDate.seconds || lastUpdatedDate);
                    break;
                case AssetState.ManualVerified:
                    this.asset.mappedWith = assetData.resolved;
                    this.getSnowLabelAndDate(assetData);
                    break;
                case AssetState.AutoVerified:
                    this.getSnowLabelAndDate(assetData);
                    if (assetData.autoResolved && Object.keys(assetData.autoResolved).length > 0) {
                        const cleanedAutoResolved = {};
                        Object.keys(assetData.autoResolved).forEach(field => {
                            cleanedAutoResolved[field] = assetData.autoResolved[field].executed?.value || '';
                        });
                        this.asset.mappedWith = cleanedAutoResolved;
                        break;
                    }
                    if (assetData.deduped?.length > 0) {
                        this.asset.mappedWith = assetData.scanned.find(x => x.scannedId == assetData.deduped[0]).data;
                    }
                    break;
                default:
                    // It is current asset, which we are trying to resolved.
                    this.asset.mappedWith = assetData.original;
                    break;
                }
            } else {
                this.asset.mappedWith = assetData.original;
                if (!this.selectedOriginalFilter && assetData.original.lastUpdated.source == LastUpdatedSource.ServiceNow) {
                    this.mappedWithColDateLabel = conflictResolutionConst.inSnowAt;
                } else {
                    this.mappedWithColDateLabel = null;
                }
                this.mappedWithColDate = Utility.formateDate(lastUpdatedDate.seconds || lastUpdatedDate);
            }
            this.assetFromOriginalIsNew = !(assetData.original.assetTag && assetData.original.serialNumber && this.selectedOriginalFilter)
            if (this.asset.mappedWith.locationId && this.asset.mappedWith.buildingId) {
                this.asset.mappedWith.Site = `${ this.asset.mappedWith.locationId }@${ this.asset.mappedWith.buildingId }`;
            }
            this.getConflictedKeys();
            this.loading = false;
        },
        getConflictedKeys() {
            const keys = conflictKeysToIncludeForTextHighlight;
            this.inConflictkeys = [];
            keys.forEach(key => {
                let mappedWithValue = this.asset.mappedWith[key]?.value || this.asset.mappedWith[key];
                let selectedScanDataValue = this.selectedScanData?.data[key]?.value || this.selectedScanData?.data[key];
                mappedWithValue = mappedWithValue?.toLowerCase();
                selectedScanDataValue = selectedScanDataValue?.toLowerCase();
                const valueArray = [mappedWithValue, selectedScanDataValue].filter(x => x);
                const hasKeyConflict = uniq(valueArray).length > 1;
                if (hasKeyConflict) {
                    this.inConflictkeys.push(key);
                }
            });
        },
        getSnowLabelAndDate(assetData) {
            if (!this.selectedOriginalFilter) {
                const lastUpdatedDate = assetData.original.lastUpdated.datetime;
                if (assetData.original.lastUpdated.source == LastUpdatedSource.ServiceNow) {
                    this.mappedWithColDateLabel = conflictResolutionConst.inSnowAt;
                } else {
                    this.mappedWithColDateLabel = null;
                }
                this.mappedWithColDate = Utility.formateDate(lastUpdatedDate.seconds || lastUpdatedDate);
            } else {
                this.mappedWithColDateLabel = conflictResolutionConst.resolvedAt;
                const physicalState = assetData.verification?.physical;
                if (assetData.state == AssetState.ManualVerified) {
                    this.mappedWithColDate = Utility.formateDate(physicalState.verifiedWithResolvedConflicts?.when.seconds);
                } else if (assetData.state == AssetState.AutoVerified) {
                    this.mappedWithColDate = Utility.formateDate(physicalState.verifiedWithoutConflicts?.when.seconds);
                }
            }
        },
        updateRadioSelection(field, $event) {
            this.selectAllType = null;
            this.radioSelection[field] = $event.target.value;
        }
    }
};
</script>
<style scoped>
.edited {
    bottom: 0.25rem;
}
tbody .color-row:nth-child(n+1):nth-child(even), .header {
    background-color: #ECEFF1;
}
</style>
<style>
.p-dropdown-panel.dropdown-panel {
    width: 6vw !important;
}
.pi-image:hover {
    cursor: pointer !important;
}
.p-dropdown-item{
    padding-bottom: 0 !important;
}
.p-dropdown-item-group{
    padding-bottom: 0 !important;
}
.p-progress-spinner-svg {
    width: 6.25rem !important;
}
.p-radiobutton .p-radiobutton-box {
    width: 1rem !important;
    height: 1rem !important;
}
.p-radiobutton {
    width: 1rem !important;
}
.school-excel-col, .auto-resolve-col {
    width: 23rem !important;
}
.scanned-by-col {
    width: 20.625rem !important;
}
.scan-dropdown, .user-dropdown {
    width: 9.375rem !important;
}
.original-dropdown {
    width: 24rem !important;
}
.header-col {
    width: 8rem !important;
}
.deferred-txt {
    word-break: break-all;
    overflow-x: hidden;
    max-height: 12.5rem
}
</style>